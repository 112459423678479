<template>
	<!-- 项目-团队 -->
	<div class="container">
		<GModule class="detail-all">
			<GTitle name="项目成员" style="margin-top: 20px;">
				<a-button class="clo1" @click="openAdd()">添加成员</a-button>
			</GTitle>
			<a-table
				class="ttable" 
				:columns="columnsHe" 
				:data-source="data" 
				:scroll="{ x: 500,y:450}" 
				bordered
				:pagination="false">
				<template #bodyCell="{ column ,record}">
				    <template v-if="column.dataIndex === 'operation'">
						<div class="caozuo">
							<!-- <a-popconfirm
								title="是否移除该成员?"
								@confirm="delBtn(record.id)">
								<p>移除</p>
							</a-popconfirm> -->
							<p @click="delBtn(record.id)">移除</p>
						</div>
					</template>
				</template>
			</a-table>
		</GModule>	
	</div>
</template>

<script>
	export default{
		data(){
			return{
				  columnsHe: [{
				      title: '姓名',
				      width: 100,
				      dataIndex: 'nickname'
				    },{
				      title: '账号',
				      width: 100,
				      dataIndex: 'username'
				    },  {
				      title: '角色',
				      width: 80,
				      dataIndex: 'group_name'
				    },{
				      title: '类型',
				      width: 100,
				      dataIndex: 'user_type_text'
				    },  {
				      title: '操作',
				      width: 120,
				      dataIndex: 'operation'
				    }],
				    data: [],
			}
		},
		computed:{
			proId(){
				return this.$store.state.proId  
			},
			isFresh(){
				return this.$store.state.isFresh
			}
		},
		watch:{
			isFresh(val,oldvalue){
				// 添加后刷新数据
				if(val){
					this.$store.commit('setIsFresh',0)
					this.getList()
				}
			},
			proId(val,oldvalue){
				if(val){
					this.getList()
				}
			}
		},
		created() {
			this.getList()
		},
		methods:{
			openAdd(){
				this.$store.commit('setIsMemberAdd',1)
			},
			async getList(){
				// 获取成员列表
				let res = await this.$utils.api.post({
					url:'/team/team_index',
					data:{
						project_id:this.proId
					},
					result:1
				})
				if(res.code == 1){
					this.data = res.data.team.rows
				}
			},
			async delBtn(id){
				// 移除成员
				let res = await this.$utils.api.post({
					url:'/team/del',
					data:{
						ids:id
					},
					result:1
				})
				if(res.code == 1){
					this.data = this.data.filter(item => item.id != id)
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.container{
		margin: 20px;
		overflow-y: auto;
		overflow-x: hidden;
		.add{
			width: 40px;
			height: 40px;
			margin-left: 20px;
			.ant-btn-icon-only{
				width: 40px !important;
				height: 40px !important;
				padding:0 !important;
			}
		}
		.caozuo{
			display: flex;
			justify-content: space-around;
			align-items: center;
			p{
				margin: 0;
				font-size: 12px;
				color: #407cff;
			}
		}
	}
</style>